import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { WikusSection } from '@wikus-ui/components/core/spacing';
import WikusPageLayout from '@wikus-ui/components/PageLayout';

import { RootState } from 'src/reducers';

import { CustomerStatistics } from '../../components/customer/statistics';
import { CustomerUsers } from './../../components/customer/users/index';

import { Customer } from 'src/model/customer';
import { Grid } from '@material-ui/core';
import { WikusButton } from '@wikus-ui/components/core/button';
import { WikusPanel } from '@wikus-ui/components/core/card';

import * as CustomerActions from './../../actions/customer';
import { CustomerRouteParams } from './models';
import { CustomerSettings } from '../../components/customer/settings';
import { CustomerStorage } from '../../components/customer/storage';
import { NavigationUrl } from '@wikus-ui/components/core/hero';
import { Redirect, Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { RoleRoute } from '../../routes/role';
import { Routes } from '../../routes';
import { useActions } from '../../actions';
import { useUser } from '../../hooks/user';

export type TCustomerProps = {
  customer?: Customer;
};
const ChildRoutes = {
  statistics: Routes.customer + '/statistics',
  users: Routes.customer + '/users',
  storage: Routes.customer + '/storage',
  settings: Routes.customer + '/settings',
};

export const CustomerPage = () => {
  const user = useUser();
  const intl = useIntl();
  const { id: customerId } = useParams<CustomerRouteParams>();
  const isUsersRoute = useRouteMatch(ChildRoutes.users);
  const isStorageRoute = useRouteMatch(ChildRoutes.storage);
  const isStatisticsRoute = useRouteMatch(ChildRoutes.statistics);
  const isSettingsRoute = useRouteMatch(ChildRoutes.settings);

  const customerActions: typeof CustomerActions = useActions(CustomerActions);
  const customers = useSelector((state: RootState) => state.customers);
  const customer = customers.find(c => c.id === customerId);

  const history = useHistory();
  const navigate = (routeKey: keyof typeof ChildRoutes) => {
    const route = ChildRoutes[routeKey];
    history.push(route.replace(':id', customerId));
  };

  useEffect(() => {
    customerActions.fetchDetails(customerId);
  }, [])

  const navigationUrls: NavigationUrl[] = customer ? [
    { url: ChildRoutes.statistics.replace(':id', customerId), label: intl.formatMessage({ id: 'navigation.statistics' }) },
    user.role !== 'U' && { url: ChildRoutes.storage.replace(':id', customerId), label: intl.formatMessage({ id: 'navigation.details' }) },
    user.role !== 'U' && { url: ChildRoutes.settings.replace(':id', customerId), label: intl.formatMessage({ id: 'navigation.settings' }) }
  ].filter(r => r) as NavigationUrl[] : [];

  return (
    <WikusPageLayout
      userName={`${user.firstName} ${user.lastName}`}
      userRole={intl.formatMessage({ id: `roles.${user.role}` })}
      title={customer?.customerName}
      heroWithImage={true}
      navigationUrls={navigationUrls}
    >
      {!isStatisticsRoute && (
        <WikusSection spacingDirection="down">
          <WikusPanel title={intl.formatMessage({ id: 'customers.tabs.title' })}>
            <Grid container direction="row" wrap="nowrap" spacing={2}>
              {(isSettingsRoute || isUsersRoute) && (
                <Grid item>
                  <WikusButton
                    color="primary"
                    variant={isUsersRoute ? 'contained' : 'outlined'}
                    onClick={() => navigate('users')}
                  >
                    {intl.formatMessage({ id: 'customers.tabs.users' })}
                  </WikusButton>
                </Grid>
              )}
              {(isSettingsRoute || isUsersRoute) && (
                <Grid item>
                  <WikusButton
                    color="primary"
                    variant={isSettingsRoute ? 'contained' : 'outlined'}
                    onClick={() => navigate('settings')}
                  >
                    {intl.formatMessage({ id: 'customers.tabs.settings' })}
                  </WikusButton>
                </Grid>
              )}
              {isStorageRoute && (
                <Grid item>
                  <WikusButton
                    color="primary"
                    variant={isStorageRoute ? 'contained' : 'outlined'}
                    onClick={() => navigate('storage')}
                  >
                    {intl.formatMessage({ id: 'customers.tabs.storage' })}
                  </WikusButton>
                </Grid>
              )}
            </Grid>
          </WikusPanel>
        </WikusSection>
      )}

      <WikusSection spacingDirection="down">
        <RoleRoute roles={['A', 'CA']}>
          <Route component={CustomerUsers} path={ChildRoutes.users} />
          <Route component={CustomerSettings} path={ChildRoutes.settings} />
          <Route component={CustomerStorage} path={ChildRoutes.storage} />
          <Route component={CustomerStatistics} path={ChildRoutes.statistics} />
          <Redirect to={ChildRoutes.statistics} />
        </RoleRoute>
        <RoleRoute role="U">
          <Route component={CustomerStorage} path={ChildRoutes.storage} />
          <Redirect to={ChildRoutes.storage} />
        </RoleRoute>
      </WikusSection>
    </WikusPageLayout>
  );
};
