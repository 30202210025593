import { Customer } from './customer';
import { RequestPayload, ResponsePayload } from './request';

export interface CustomerUser {
  id: number;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  isActive: boolean;
  features: string[];

  createdAt: string;
  language: string;
  country: string;
  currency: string;

  customer: Customer;

  orderMail?: string;
  sendToCustomer?: boolean;
  featureAutoCreateArticles?: boolean;
}

export interface CreateCustomerUser extends Pick<CustomerUser, 'name' | 'email' | 'firstName' | 'lastName' | 'role'> {
  password: string;
}

export interface EditCustomerUser extends Pick<CustomerUser, 'country' | 'language' | 'sendToCustomer' | 'orderMail'> { };

export enum CustomerUserActions {
  FETCH_CUSTOMER_USERS = 'FETCH_CUSTOMER_USERS',
  FETCH_CUSTOMER_USERS_SUCCESS = 'FETCH_CUSTOMER_USERS_SUCCESS',
  FETCH_CUSTOMER_USERS_FAIL = 'FETCH_CUSTOMER_USERS_FAIL',

  CREATE_CUSTOMER_USER = 'CREATE_CUSTOMER_USER',
  CREATE_CUSTOMER_USER_SUCCESS = 'CREATE_CUSTOMER_USER_SUCCESS',
  UPDATE_CUSTOMER_USER = 'UPDATE_CUSTOMER_USER',
  UPDATE_CUSTOMER_USER_SUCCESS = 'UPDATE_CUSTOMER_USER_SUCCESS',
  DELETE_CUSTOMER_USER = 'DELETE_CUSTOMER_USER',
  DELETE_CUSTOMER_USER_SUCCESS = 'DELETE_CUSTOMER_USER_SUCCESS'
}

interface CustomerUserActionType<T, P> {
  type: T;
  payload: P;
  asyncDispatch?: any;
}

export type FetchSuccessCustomerUserPayload = CustomerUser[];
export type DeleteSuccessCustomerUserPayload = { id: number };

export type CustomerUserAction =
  | CustomerUserActionType<typeof CustomerUserActions.FETCH_CUSTOMER_USERS, RequestPayload>
  | CustomerUserActionType<typeof CustomerUserActions.FETCH_CUSTOMER_USERS_SUCCESS, ResponsePayload<FetchSuccessCustomerUserPayload>>
  | CustomerUserActionType<typeof CustomerUserActions.FETCH_CUSTOMER_USERS_FAIL, null>

  | CustomerUserActionType<typeof CustomerUserActions.CREATE_CUSTOMER_USER, RequestPayload>
  | CustomerUserActionType<typeof CustomerUserActions.CREATE_CUSTOMER_USER_SUCCESS, ResponsePayload<CustomerUser>>

  | CustomerUserActionType<typeof CustomerUserActions.UPDATE_CUSTOMER_USER, RequestPayload>
  | CustomerUserActionType<typeof CustomerUserActions.UPDATE_CUSTOMER_USER_SUCCESS, ResponsePayload<CustomerUser>>

  | CustomerUserActionType<typeof CustomerUserActions.DELETE_CUSTOMER_USER, RequestPayload>
  | CustomerUserActionType<typeof CustomerUserActions.DELETE_CUSTOMER_USER_SUCCESS, ResponsePayload<DeleteSuccessCustomerUserPayload>>;
