import { OnSubmitHandler, useInputGroup } from '@wikus-ui/hooks/useInputGroup';
import { WikusButton, WikusButtonRow } from '@wikus-ui/components/core/button';
import { WikusInputRow, WikusTextField } from '@wikus-ui/components/core/input';
import { useIntl } from 'react-intl';

import * as CustomerUserActions from '../../../actions/customer-user';
import * as InfoActions from '../../../actions/info';

import {
  CustomerUser,
  CustomerUserActions as CustomerUserActionsModel
} from '../../../model/customer-user';
import { useActions } from '../../../actions';

import { Email } from '@wikus-ui/components/utils/validators';
import { WikusSwitch } from '@wikus-ui/components/core/switch';
import { useEffect } from 'react';
import { useUser } from '../../../hooks/user';

type TEditCustomerFormProps = {
  onEdit?: () => void;
  onAbort?: () => void;
  customerUser: CustomerUser | null;
};

export const EditCustomerForm = ({
  onEdit,
  onAbort,
  customerUser,
}: TEditCustomerFormProps) => {
  const user = useUser();
  const intl = useIntl();
  const EditCustomerInputGroup = 'edit-customer';

  const customerUserActions: typeof CustomerUserActions =
    useActions(CustomerUserActions);
  const infoActions: typeof InfoActions = useActions(InfoActions);

  const _onSubmit: OnSubmitHandler = async () => {
    if (!isValid) {
      return;
    }

    const { type } = await customerUserActions.updateCustomerUser(
      value,
      // we can only see the form if there is a user
      customerUser!.id
    );

    const isSuccess =
      type === CustomerUserActionsModel.UPDATE_CUSTOMER_USER_SUCCESS;

    if (isSuccess) {
      onEdit && onEdit();
    }

    return isSuccess;
  };

  const {
    value,
    setGroup,
    isValid,
    onSubmit,
    createInputProps,
    createSwitchProps,
    isSubmitting,
  } = useInputGroup(EditCustomerInputGroup, _onSubmit);

  useEffect(() => {
    setGroup({
      value: {
        country: customerUser?.country,
        language: customerUser?.language,
        orderMail: customerUser?.orderMail,
        sendToCustomer: customerUser?.sendToCustomer,
        featureAutoCreateArticles: customerUser?.featureAutoCreateArticles,
      },
    });
  }, [customerUser]);

  useEffect(() => {
    infoActions.fetchInfo();
  }, []);

  console.log(value);

  return (
    <form onSubmit={onSubmit}>
      <WikusInputRow>
        <WikusSwitch
          xs={6}
          {...createSwitchProps('sendToCustomer')}
          color="primary"
        />
        <WikusTextField
          {...createInputProps(
            'orderMail',
            value?.sendToCustomer ? Email : null
          )}
          xs={6}
        />
      </WikusInputRow>
      {
        user.role === 'A' && (
          <WikusInputRow>
            <WikusSwitch
              xs={12}
              {...createSwitchProps('featureAutoCreateArticles')}
              color="primary"
            />
          </WikusInputRow>
        )}
      <WikusButtonRow>
        <WikusButton type="submit" color="primary" disabled={isSubmitting || isValid === undefined}>
          {intl.formatMessage({
            id: 'create-user.submit',
          })}
        </WikusButton>
        <WikusButton
          onClick={onAbort}
          color="primary"
          variant="outlined"
          disabled={isSubmitting}
        >
          {intl.formatMessage({
            id: 'create-user.abort',
          })}
        </WikusButton>
      </WikusButtonRow>
    </form>
  );
};
